import React, { Fragment, useEffect } from "react";
import { useIntl } from 'react-intl'
import style from './index.module.less'
import {
    specs_air2pro, specs_air2, specs_air
} from '@/images/air2/index'
import { buy_link, spec_price } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { XButton } from '@/components/index'
import { SaleLogo } from '@/components/index'
import useNavigate from '@/hooks/useNavigate'
import classNames from 'classnames'
import { useInView } from "react-intersection-observer"
import {
    buyAll_air,
    buyAll_air_ada,
    buyAll_air_beam,
} from '@/images/air/index'
import { useGTM } from '@/hooks/useGTM.js'

const BuyAll = () => {

    const intl = useIntl()
    const countryCode = getCountry()
    const region = getCountry()
    const [btnRef, btnRefInview] = useInView({ threshold: 0 });
    useEffect(() => {
        if (!!btnRefInview) {
            typeof sensors !== "undefined" && sensors.track('module_view', {
                custom: {
                    module_id: 20047
                }
            });
        }
    }, [btnRefInview])
    const list = [
        {
            header: {
                img: buyAll_air,
                name: 'Air',
                price: spec_price[countryCode]['air'],
                shop: buy_link[countryCode]['air'].shop || buy_link[countryCode]['air'].amazon,
                pro_name: "air",
                shop_sale: !!buy_link[countryCode]['air'].sale_shop_price ? buy_link[countryCode]['air'].sale_shop_price : false
            },
            list: [
                {
                    text: intl.formatMessage({ id: "air.access.prod1.des1" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod1.des2" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod1.des3" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod1.des4" }),
                },
                {
                    text: ' ',
                }
            ]
        },
        {
            header: {
                img: buyAll_air_ada,
                name: 'Air+Adapter',
                price: buy_link[countryCode]['air_ada'].price,
                shop: buy_link[countryCode]['air_ada'].shop,
                pro_name: "air_adapter",
                shop_sale: !!buy_link[countryCode]['air_ada'].sale_price ? buy_link[countryCode]['air_ada'].sale_price : false,
                sold_out:countryCode=='jp'? true:false
            },
            list: [
                {
                    text: intl.formatMessage({ id: "air.access.prod2.des1" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod2.des2" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod1.des3" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod1.des4" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod2.des3" }),
                }
            ]
        },
        {
            header: {
                img: buyAll_air_beam,
                name: 'Air+Beam',
                price: buy_link[countryCode]['air_beam'].price,
                shop: buy_link[countryCode]['air_beam'].shop,
                pro_name: "air_beam",
                shop_sale: !!buy_link[countryCode]['air_beam'].sale_price ? buy_link[countryCode]['air_beam'].sale_price : false
            },
            list: [
                {
                    text: intl.formatMessage({ id: "air.access.prod2.des1" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod2.des2" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod3.des1" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod1.des4" }),
                }, {
                    text: intl.formatMessage({ id: "air.access.prod3.des2" }),
                }
            ]
        },
    ]
    const addSensor = (id, name) => {
        typeof sensors !== "undefined" && sensors.track('module_interaction', {
            custom: {
                module_id: id,
                products_name: name
            }
        });
    }
    return (
        <>

            <div className={style.spec} >
                <h2 className={style.specTitle} id='air2spec'>{intl.formatMessage({ id: "air.access.title" })}</h2>
                <div className={style.specList} >
                    {list.map((item, index) => (
                        <Fragment key={index}>
                            <div className={style.specListColHeader} >
                                <img className={style.specListColHeaderImg} src={item.header.img} />

                                <div className={style.specListColHeaderName} >{item.header.name}</div>

                                <div className={style.specListColHeaderPrice}>
                                    {!!item.header.sold_out ? '-' : <>  <span style={{ textDecoration: !!item.header.shop_sale ? 'line-through' : "none" }}>{item.header.price}</span>{item.header.shop_sale && <span className={style.specListColHeaderPriceSale}>{item.header.shop_sale}</span>}</>}

                                </div>

                                {!!item.header.shop && (!!item.header.sold_out ? <><XButton
                                    styles={{ width: '140px', margin: "20px auto 0",cursor:"unset" }} >
                                    <div className={style.specListColHeaderBtn_black} ref={btnRef} >
                                    売り切れ
                                    </div>
                                </XButton></> : (<XButton

                                    styles={{ width: '140px', margin: "20px auto 0" }} onClick={() => {
                                        addSensor(20045, item?.header?.pro_name);

                                        window.open(item.header.shop)
                                        useGTM('products_click', 'Buy Now', item.header.name, 'list_Spec')

                                    }}>
                                    {item.header.shop_sale && <SaleLogo text={buy_link[region]['air'].sale_shop_reduce} />}

                                    <div className={style.specListColHeaderBtn_black} ref={btnRef} >
                                        {intl.formatMessage({ id: "specs_buy_now", defaultMessage: "Buy Now" })}
                                    </div>
                                </XButton>))}

                            </div>
                            {item?.list?.map((ite, idx) => (
                                <div className={style.specListColItem} key={idx}>
                                    {ite.text == '-' ?
                                        <div className={classNames(style.specListColItemLabel, style.line)}>{'-'}</div> : <Fragment>

                                            {!!ite.text && <div className={style.specListColItemText} dangerouslySetInnerHTML={{ __html: ite.text }}></div>}
                                        </Fragment>}
                                </div>
                            ))}
                        </Fragment>
                    ))}
                </div>
            </div>
        </>

    )
}

export default BuyAll